import clientWrapper from '../../clientWrapper'

import { IGenericResponse } from '../../api.types'

import { IClaim } from '../../../models/assets/Claim.model'

import { claimFragment } from './fragments'
import { MAX_RETRIES, SERVICE_NAME } from '../../../constants'

export type IReleaseClaimResponse = IGenericResponse<'releaseClaim', IClaim>

export async function releaseClaim(
  { claimId }: { claimId: string },
  fragment: string = claimFragment
): Promise<IReleaseClaimResponse> {
  const query = `
    mutation releaseClaim ($claimId: String!) {
      releaseClaim (claimId: $claimId) {
        ${fragment}
      }
    }
  `
  return clientWrapper(SERVICE_NAME.ASSETS, query, { claimId }, MAX_RETRIES) as Promise<IReleaseClaimResponse>
}
