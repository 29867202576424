import { flow, Instance, types, cast } from 'mobx-state-tree'
import { toast } from 'react-toastify'
import { ddexUploadCsv, IDdexUploadCsvResponse } from '../../api/assets-api/ddex/ddexUploadCsv'
import { Asset } from './Asset.model'

import { DdexRelease } from './DdexRelease.model'
import { ASSET_TYPE } from '../../constants'

export const DdexReleaseList = types
  .model({
    list: types.array(DdexRelease),
  })
  .volatile(() => ({
    loading: false,
  }))
  .actions(self => ({
    clearList() {
      self.loading = false
      self.list = cast([])
    },
    byReleaseUuid(uuid: string | null | undefined) {
      if (!uuid) {
        return undefined
      }
      return self.list.find(el => el.release.uuid === uuid)
    },
    ddexGenerateAssetsFromCsv: flow(function* (csvString: string, audios: string[], images: string[]) {
      try {
        self.loading = true
        const releaseUuids: string[] = []
        const res: IDdexUploadCsvResponse = yield ddexUploadCsv({ csvString, audios, images })

        if (res?.data.data?.ddexUploadCsv.errors && res?.data.data?.ddexUploadCsv.errors.length > 0) {
          console.error(res?.data.data?.ddexUploadCsv.errors)
          res?.data.data?.ddexUploadCsv.errors.forEach(err => {
            toast.error(err)
          })
          throw new Error('CSV string upload error.')
        }

        if (res?.data.data?.ddexUploadCsv.assets) {
          const rawAssets = res.data.data.ddexUploadCsv.assets
          rawAssets.forEach(asset => {
            if (asset.assetTypes?.includes(ASSET_TYPE.RELEASE)) {
              const newDdexRelease = DdexRelease.create({
                release: asset,
                audios: [],
              })

              releaseUuids.push(asset.uuid || '')

              asset.audios?.forEach(audio => {
                const childAsset = rawAssets.find(fa => fa.uuid === audio.uuid)
                if (childAsset) {
                  newDdexRelease.addAudios(Asset.create(childAsset))
                }
              })

              self.list.push(newDdexRelease)
            }
          })
        }
        self.loading = false

        return releaseUuids
      } catch (err) {
        self.loading = false
        console.error(err)
        return []
      }
    }),
  }))

export type IDdexReleaseList = Instance<typeof DdexReleaseList>
