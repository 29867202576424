import { SERVICE_NAME } from '../../../constants'
import { IAsset } from '../../../models/assets/Asset.model'
import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'
import { audioFragment } from '../fragments'

export type IAudioReleaseLoadAudiosResponse = IGenericResponse<'findAssetByUuid', { audios: IAsset[] }>

export async function audioReleaseLoadAudios(
  uuid: string,
  fragment: string = audioFragment
): Promise<IAudioReleaseLoadAudiosResponse> {
  const variables = { uuid }
  const query = `
    query AudioRelease_loadAudios ($uuid: String!) {
      findAssetByUuid (uuid:$uuid) {
        uuid
        audios {
          ${fragment}
        }
      }
    }
  `
  return clientWrapper(SERVICE_NAME.ASSETS, query, variables) as Promise<IAudioReleaseLoadAudiosResponse>
}
