import { SERVICE_NAME } from '../../../constants'
import { IUserSite } from '../../../models/UserSite.model'
import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'

export type ISetMainUserSiteProps = {
  siteUuid: string
  userUuid: string
}

export type ISetMainUserSiteResponse = IGenericResponse<'setMainUserSite', IUserSite[]>

export async function setMainUserSite({
  siteUuid,
  userUuid,
}: ISetMainUserSiteProps): Promise<ISetMainUserSiteResponse> {
  const query = `
    mutation setMainUserSite ($siteUuid: String!, $userUuid: String!) {
      setMainUserSite (
        siteUuid: $siteUuid
        userUuid: $userUuid
      ) {
        uuid
        siteUuid
        active
        affiliateId
        affiliatePercentage
        isMain
        site {
          uuid
          name
        }
      }
    }
  `

  return (await clientWrapper(SERVICE_NAME.USERS, query, { siteUuid, userUuid })) as ISetMainUserSiteResponse
}
