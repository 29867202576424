import { APPROVAL_STATES, SERVICE_NAME } from '../../../constants'
import { IAssetBasic } from '../../../models/assets/AssetBasic.model'
import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'

type TChangeAssetStatesInput = {
  itemId: string
  targetState: APPROVAL_STATES
  comment?: string
}

export type IChangeAssetStatesResponse = IGenericResponse<'changeAssetStates', IAssetBasic[]>

export async function changeAssetStates(
  changeStateInputs: TChangeAssetStatesInput[]
): Promise<IChangeAssetStatesResponse> {
  const props = { changeStateInputs }
  const query = `
    mutation changeAssetStates (
      $changeStateInputs: [ChangeStateInput!]!
    ){
      changeAssetStates ( changeStateDataList: $changeStateInputs){
        uuid
 
      }
    }
  `
  return clientWrapper(SERVICE_NAME.ASSETS, query, props) as Promise<IChangeAssetStatesResponse>
}
