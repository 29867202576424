import { SERVICE_NAME } from '../../../constants'
import { ILabel } from '../../../models/Label.model'
import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'

export type ICreateLabelResponse = IGenericResponse<'createLabel', ILabel>

export type ICreateLabelProps = {
  name: string
}

export async function createLabel({ name }: ICreateLabelProps): Promise<ICreateLabelResponse> {
  const query = `
    mutation ($name: String!) {
      createLabel (name: $name) {
        uuid
        name
      }
    }
  `

  return clientWrapper(SERVICE_NAME.USERS, query, { name }) as Promise<ICreateLabelResponse>
}
