import { ReactNode } from 'react'
import { IUser } from '@epic-front/common/src/models/User.model'

export enum RouteType {
  'PUBLIC',
  'AUTH_PROTECTED',
  'PUBLIC_ACCOUNT_PAGES',
}
export interface IRoute {
  url: string
  name: string
  icon?: string
  header?: string
  exact?: boolean
  element: ReactNode
  roles?: string[]

  permissionsAnd?: string[]
  permissionsOr?: string[]
  shouldHaveRoute?: (user?: IUser) => boolean
  key: string
  routeType?: RouteType
}

export interface ISiteRouteList {
  [key: string]: IRoute
}
