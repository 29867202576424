import { Instance, types } from 'mobx-state-tree'
import { Asset, IAsset } from './Asset.model'

export const DdexRelease = types
  .model({
    release: Asset,
    audios: types.array(Asset),
  })
  .volatile(() => ({
    loading: false,
  }))
  .views(self => ({
    get allAssetsHaveFiles() {
      return self.audios.reduce((a, c) => !!c.downloadUrl && a, true)
    },
  }))
  .actions(self => ({
    addAudios(asset: IAsset) {
      self.audios.push(asset)
    },
  }))

export type IDdexRelease = Instance<typeof DdexRelease>
