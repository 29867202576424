import { types, Instance, cast, detach, flow } from 'mobx-state-tree'
import moment from 'moment'
import {
  ICreateOrEditAnnouncementResponse,
  createOrEditAnnouncement,
  deleteAnnouncement,
} from '../api/users-api/announcements'
import { ANNOUNCEMENT_STATUS } from '../constants'
import { toggleAnnouncement } from '../api/users-api/announcements/toggleAnnouncement'

export const Announcement = types
  .model({
    uuid: types.maybeNull(types.string),
    title: types.maybeNull(types.string),
    text: types.maybeNull(types.string),
    startDate: types.maybeNull(types.string),
    endDate: types.maybeNull(types.string),
    updatedAt: types.maybeNull(types.string),
    updatedBy: types.maybeNull(types.string),
    type: types.maybe(types.string),
    siteUuids: types.array(types.string),
    isEnabled: types.maybeNull(types.boolean),
    status: types.maybeNull(types.enumeration(Object.values(ANNOUNCEMENT_STATUS))),
  })
  .volatile(() => ({
    loading: false,
  }))
  .actions(self => ({
    setTitle(title: string) {
      self.title = title
    },
    setText(text: string) {
      self.text = text
    },
    setType(type: string) {
      self.type = type
    },
    setStartDate(date: string) {
      self.startDate = date
    },
    setEndDate(date: string) {
      self.endDate = date
    },
    setSites(list: string[]) {
      detach(self.siteUuids)
      self.siteUuids = cast(list)
    },
    setEnable(val: boolean) {
      self.isEnabled = val
    },
  }))
  .actions(self => ({
    toggleEnable: flow(function* () {
      try {
        self.loading = true
        if (self.uuid) {
          yield toggleAnnouncement({ uuid: self.uuid })
        }

        self.loading = false
      } catch (e) {
        self.loading = false

        console.error(e)
      }
    }),
    delete: flow(function* () {
      try {
        self.loading = true
        if (self.uuid) {
          yield deleteAnnouncement({ uuid: self.uuid })
        }

        self.loading = false
      } catch (e) {
        self.loading = false

        console.error(e)
      }
    }),
    createOrUpdate: flow(function* () {
      try {
        self.loading = true

        const resp: ICreateOrEditAnnouncementResponse = yield createOrEditAnnouncement({
          startDate: self.startDate || moment().utc().add('1', 'hour').toString(),
          endDate: self.endDate || moment().utc().add('1', 'day').toString(),
          siteUuids: self.siteUuids,
          text: self.text || '',
          title: self.title || '',
          type: self.type || 'INFO',
          uuid: self.uuid || undefined,
        })

        if (resp?.data.data?.createOrEditAnnouncement.uuid) {
          self.uuid = resp.data.data.createOrEditAnnouncement.uuid
          return resp.data.data.createOrEditAnnouncement.uuid
        }

        self.loading = false
        return ''
      } catch (e) {
        self.loading = false

        console.error(e)
        return ''
      }
    }),
  }))

export type IAnnouncement = Instance<typeof Announcement>
