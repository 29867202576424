import { cast, flow, Instance, types } from 'mobx-state-tree'

import { Pagination } from '../Pagination.model'
import { License } from './License.model'
import { getLicenses, IGetLicensesResponse } from '../../api/assets-api/licenses/getLicenses'

export const LicensesList = types
  .model({
    list: types.array(License),
  })
  .volatile(() => ({
    pagination: Pagination.create({ totalItems: 1 }),
    loading: false,
    userUuidFilter: '',
    uuidFilter: '',
    typeFilter: '',
    channelIdFilter: '',
  }))
  .actions(self => ({
    setUserUuidFilter(userUuid: string) {
      self.userUuidFilter = userUuid
    },
    setTypeFilter(type: string) {
      self.typeFilter = type
    },
    setUuidFilter(uuid: string) {
      self.uuidFilter = uuid
    },
    setChannelIdFilter(channelId: string) {
      self.channelIdFilter = channelId
    },
    load: flow(function* () {
      try {
        self.loading = true
        const variables = {
          pagination: self.pagination.allQueryParams,
          filters: {
            ...(self.typeFilter && { type: self.typeFilter }),
            ...(self.userUuidFilter && { userUuid: self.userUuidFilter }),
            ...(self.uuidFilter && { uuid: self.uuidFilter }),
            ...(self.channelIdFilter && { channelId: self.channelIdFilter }),
          },
        }

        const resp: IGetLicensesResponse = yield getLicenses(variables)

        if (resp && resp.data.data?.assetLicenses) {
          self.list = cast(resp.data.data.assetLicenses.assetLicenses)

          self.pagination.setTotalItems(resp.data.data.assetLicenses.total)
        }

        self.loading = false
      } catch (e) {
        console.error(e)
      } finally {
        self.loading = false
      }
    }),
  }))

export type ILicensesList = Instance<typeof LicensesList>
