import { cast, flow, Instance, types } from 'mobx-state-tree'
import { toast } from 'react-toastify'

import { Pagination } from '../Pagination.model'
import { getPitchesAsUser, IGetPitchesAsUserResponse } from '../../api/assets-api/songpitch/getPitchesAsUser'
import { IPitch, Pitch } from './Pitch.model'
import { getPitch, IGetPitchResponse } from '../../api/assets-api/songpitch/getPitch'
import {
  updatePitchesAsLoggedIn,
  IUpdatePitchesAsLoggedInResponse,
} from '../../api/assets-api/songpitch/updatePitchesAsLoggedIn'
import { IChannel } from '../assets/Channel.model'
import { getPitchesAsChannel, IGetPitchesAsChannelResponse } from '../../api/assets-api/songpitch/getPitchesAsChannel'
import { CHANNEL_PITCH_STATUS, PITCH_STATUS } from '../../constants/songpitch'

export const PitchList = types
  .model({
    list: types.array(Pitch),
  })
  .volatile(() => ({
    pagination: Pagination.create({ totalItems: 1 }),
    loading: false,
    searchTextFilter: '',
    channelUuidFilter: '',
    userUuidFilter: '',
    assetUuidFilter: '',
    genreUuidFilter: '',
    channelPitchStatusFilter: [] as CHANNEL_PITCH_STATUS[],
    statusFilter: [] as PITCH_STATUS[],
    // add filters here
  }))
  .views(self => ({
    get getGenrePitchedSum(): string[] {
      const genreUuidList: string[] = []
      self.list.forEach(p => {
        p.genres.forEach(genreUuid => {
          if (genreUuidList.indexOf(genreUuid) === -1) {
            genreUuidList.push(genreUuid)
          }
        })
      })
      return genreUuidList
    },
    get getChannelPitchedSum(): IChannel[] {
      const channelUuidList: IChannel[] = []
      self.list.forEach(p => {
        p.toChannels.forEach(chnUuid => {
          if (channelUuidList.map(chn => chn.uuid).indexOf(chnUuid.uuid) === -1) {
            channelUuidList.push(chnUuid)
          }
        })
      })
      return channelUuidList
    },
  }))
  .actions(self => ({
    loadAsPitcher: flow(function* () {
      try {
        self.loading = true
        const variables = {
          pagination: self.pagination.allQueryParams,
          filters: {
            ...(self.channelUuidFilter && { channelUuid: self.channelUuidFilter }),
            ...(self.userUuidFilter && { userUuid: self.userUuidFilter }),
            ...(self.genreUuidFilter && { genreUuid: self.genreUuidFilter }),
            ...(self.assetUuidFilter && { assetUuid: self.assetUuidFilter }),
            ...(self.statusFilter && { statuses: self.statusFilter }),
          },
        }

        const resp: IGetPitchesAsUserResponse = yield getPitchesAsUser(variables)
        if (resp && resp.data.data?.pitchesAsUser) {
          self.list = cast(resp.data.data.pitchesAsUser.pitches)
          self.pagination.setTotalItems(resp.data.data.pitchesAsUser.total)
        }

        self.loading = false
      } catch (e) {
        console.error(e)
      } finally {
        self.loading = false
      }
    }),
    loadAsChannel: flow(function* () {
      try {
        if (self.channelUuidFilter) {
          self.loading = true
          const variables = {
            pagination: self.pagination.allQueryParams,
            filters: {
              channelUuid: self.channelUuidFilter,
              ...(self.channelPitchStatusFilter && { channelPitchStatuses: self.channelPitchStatusFilter }),
            },
          }

          const resp: IGetPitchesAsChannelResponse = yield getPitchesAsChannel(variables)
          if (resp && resp.data.data?.pitchesAsChannel) {
            self.list = cast(resp.data.data.pitchesAsChannel.pitches)
            self.pagination.setTotalItems(resp.data.data.pitchesAsChannel.total)
          }

          self.loading = false
        }
      } catch (e) {
        console.error(e)
      } finally {
        self.loading = false
      }
    }),
    loadFromIds: flow(function* (ids: string[]) {
      try {
        self.loading = true
        const items: IPitch[] = []
        const list: Promise<void>[] = []
        ids.forEach(id => {
          list.push(
            getPitch({ uuid: id }).then((resp: IGetPitchResponse) => {
              if (resp?.data.data?.pitch) {
                items.push(cast(resp?.data.data?.pitch))
              }
            })
          )
        })
        yield Promise.all(list)
        self.list = cast(items)
        self.loading = false
      } catch (e) {
        console.error(e)
      } finally {
        self.loading = false
      }
    }),
    setChannelUuidFilter(uuid: string) {
      self.channelUuidFilter = uuid
    },

    setGenreUuidFilter(uuid: string) {
      self.genreUuidFilter = uuid
    },
    setUserUuidFilter(uuid: string) {
      self.userUuidFilter = uuid
    },
    setAssetUuidFilter(uuid: string) {
      self.assetUuidFilter = uuid
    },

    setStatusFilter(statuses: PITCH_STATUS[]) {
      self.statusFilter = [...statuses]
    },

    setChannelPitchStatusFilter(statuses: CHANNEL_PITCH_STATUS[]) {
      self.channelPitchStatusFilter = [...statuses]
    },
    setSearchTextFilter(searchText: string) {
      self.searchTextFilter = searchText
    },
    pitchToGenresOrChannels: flow(function* ({ toGenre, toChannels }: { toGenre?: string[]; toChannels?: string[] }) {
      try {
        self.loading = true

        const resp: IUpdatePitchesAsLoggedInResponse = yield updatePitchesAsLoggedIn({
          pitchUuids: self.list.map(p => p.uuid) as string[],
          //! need to discuss what happens when you have multiple pitches selected with different previous genres
          genres: [...self.getGenrePitchedSum, ...(toGenre || [])],
          //! same here
          toChannels: [...self.getChannelPitchedSum.map(chn => chn.uuid), ...(toChannels || [])],
        })

        if (resp?.data.data?.updatePitchesAsLoggedIn) {
          toast.success('Pitch updated')
        }

        self.loading = false
      } catch (e) {
        console.error(e)
      } finally {
        self.loading = false
      }
    }),
  }))

export type IPitchList = Instance<typeof PitchList>
