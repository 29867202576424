import { flow, Instance, applySnapshot, cast, types } from 'mobx-state-tree'
import { toast } from 'react-toastify'
import { getUserPaymentMethod } from '../../api/payments-api/payments/getUserPaymentMethod'
import { IResetPaymentMethodResponse, resetPaymentMethod } from '../../api/payments-api/payments/resetPaymentMethod'
import { updateInternalStatus } from '../../api/payments-api/payments/updateInternalStatus'
import { getRejectionReason } from '../../api/payments-api/payments/getRejectionReason'

import { JSONPrimitive } from '../../constants'

import { UserPaymentMethodBasic } from './UserPaymentMethodBasic.model'

export const UserPaymentMethod = types
  .compose(
    UserPaymentMethodBasic,
    types.model({
      readOnlyDetails: types.optional(JSONPrimitive, '{}'),
    })
  )
  .views(self => ({
    get isCompletedOrFailed() {
      return self.status && ['COMPLETED', 'FAILED'].indexOf(self.status) !== -1
    },
    get isWaitingAproval() {
      return self.status && ['WAITING_APPROVAL'].indexOf(self.status) !== -1
    },
  }))
  .volatile(() => ({
    loading: true,
  }))
  .actions(self => ({
    setReadOnlyDetails(details: string | JSON) {
      self.readOnlyDetails = cast(details)
    },
  }))
  .actions(self => ({
    reset: flow(function* () {
      try {
        if (self.userUuid) {
          const resp: IResetPaymentMethodResponse = yield resetPaymentMethod({ userUuid: self.userUuid })

          if (resp && resp.data.data?.resetPaymentMethod) {
            toast.success('User payment method was reset')
          }
        }
      } catch (err) {
        //
      }
    }),
    getRejectionReason: flow(function* (userUuid) {
      try {
        self.loading = true
        const resp = yield getRejectionReason({ userUuid })

        if (resp?.data.data?.getRejectReason) {
          self.internalStatusRejectionReason = cast(resp.data.data.getRejectReason)
        }
        self.loading = false
      } catch (err) {
        self.loading = false
        toast.error('Reject Reason call failed')
      }
    }),
    loadMethod: flow(function* (userUuid?: string) {
      try {
        self.loading = true

        const resp = yield getUserPaymentMethod({ userUuid })
        if (resp && resp.data.data?.userPaymentMethod) {
          applySnapshot(self, resp.data.data?.userPaymentMethod)
          self.setReadOnlyDetails(resp.data.data?.userPaymentMethod.details)
        }
        self.loading = false
      } catch (err) {
        self.loading = false
      }
    }),
    updateInternalStatus: flow(function* (
      userPaymentMethodUuid: string,
      internalStatus: string,
      rejectReason?: string
    ) {
      try {
        self.loading = true

        const resp = yield updateInternalStatus({
          userPaymentMethodUuid,
          internalStatus,
          rejectReason,
        })
        self.loading = false
        if (resp && resp.data.data?.updateInternalStatus) {
          toast.success(`New status: ${internalStatus}`)
        }
      } catch (err) {
        self.loading = false
      }
    }),
  }))

export type IUserPaymentMethod = Instance<typeof UserPaymentMethod>
