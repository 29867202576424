import clientWrapper from '../../clientWrapper'

import { IGenericResponse } from '../../api.types'

import { IClaim } from '../../../models/assets/Claim.model'

import { claimsFragment } from './fragments'
import { IGetClaimsProps } from './getClaims'
import { SERVICE_NAME } from '../../../constants'

export type IGetClaimsIssuesResponse = IGenericResponse<
  'claimsIssues',
  { claims: IClaim[]; total: number; totalUnique?: number }
>

export async function getClaimsIssues(
  { pagination, filters }: IGetClaimsProps,
  fragment: string = claimsFragment
): Promise<IGetClaimsIssuesResponse> {
  const query = `
    query claimsIssues ($pagination: PaginationInput, $filters: ClaimsIssuesFilterInput) {
      claimsIssues (pagination: $pagination, filters: $filters) {
        total
        totalUnique
        ${fragment}
         
      }
    }
  `

  return (await clientWrapper(SERVICE_NAME.ASSETS, query, { pagination, filters })) as IGetClaimsIssuesResponse
}
