import { Button } from 'react-bootstrap'
import { Modal } from '@blocks'

interface IDeleteModal {
  open: boolean
  closeModal: () => void
  submit: () => void
  children: JSX.Element | JSX.Element[] | string
}

export const DeleteModal = ({ open, closeModal, submit, children }: IDeleteModal): JSX.Element => {
  const Buttons = (
    <>
      <Button className="btn btn-gray-400" onClick={closeModal}>
        <i className="uil-times me-2" />
        Cancel
      </Button>
      <Button variant="danger" onClick={submit}>
        <i className="uil-check me-2" />
        Delete
      </Button>
    </>
  )

  return (
    <Modal buttons={Buttons} onHide={closeModal} title="Delete" isOpen={open} size="lg">
      {children}
    </Modal>
  )
}
