import { IChannel } from '@epic-front/common/src/models/assets/Channel.model'
import { IUser } from '@epic-front/common/src/models/User.model'
import { IMenuItem } from '../constants/menus/menu.types'

const findMenuItem = (menuItems?: IMenuItem[], menuItemKey?: string | null): IMenuItem | null => {
  if (menuItems && menuItemKey) {
    for (let i = 0; i < menuItems.length; i += 1) {
      if (menuItems[i].key === menuItemKey) {
        return menuItems[i]
      }
      const found = findMenuItem(menuItems[i].children, menuItemKey)
      if (found) return found
    }
  }
  return null
}

const findParent = (menuItems: IMenuItem[], menuItem: IMenuItem): string[] => {
  const parents: string[] = []
  menuItems.forEach(item => {
    if (item.children) {
      item.children.forEach(childItem => {
        if (childItem.key === menuItem.key) {
          parents.push(item.key)
        }
      })
    }
  })

  return parents
}

const getChildMenuList = (item: IMenuItem, user: IUser | undefined, channels?: IChannel[]): IMenuItem => {
  const kids = item.children ? [...item.children] : []
  if (item.generateMenuItems && user) {
    return { ...item, children: [...kids, ...item.generateMenuItems({ user, channels })] }
  }
  return item
}

export { findParent, findMenuItem, getChildMenuList }
