import { SERVICE_NAME } from '../../../constants'
import { IRole } from '../../../models/Role.model'
import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'
import { rolesExtended } from '../fragments'

export type IGetRolesResponse = IGenericResponse<'roles', IRole[]>

export async function getRoles(): Promise<IGetRolesResponse> {
  const query = `
  {
    ${rolesExtended}
  }
  `
  return (await clientWrapper(SERVICE_NAME.USERS, query)) as IGetRolesResponse
}
