import { IGenericResponse } from '../api.types'
import clientWrapper from '../clientWrapper'

import { SERVICE_NAME } from '../../constants'

type IClaimDailyCountsProps = {
  assetUuid?: string
  userUuid?: string
}

export type IGetClaimDailyCountsResponse = IGenericResponse<
  'claimDailyCounts',
  { days: { date: string; count: number }[]; total: number; percentage?: number | null }
>

export async function getClaimDailyCounts(input?: IClaimDailyCountsProps): Promise<IGetClaimDailyCountsResponse> {
  const query = `
    query claimDailyCounts ($input: DailyClaimsFilterInput) {
      claimDailyCounts (input: $input) {
          days {
            date
            count
          }
          total
          percentage
      } 
    }
  `

  return clientWrapper(SERVICE_NAME.ASSETS, query, { input }) as Promise<IGetClaimDailyCountsResponse>
}
