import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'
import { IRole } from '../../../models/Role.model'
import { roleFragment } from '../fragments'
import { SERVICE_NAME } from '../../../constants'

export type ICreateRoleResponse = IGenericResponse<'createRole', IRole>

export type ICreateRoleProps = {
  name: string
  description: string
  isInternal: boolean
}

export async function createRole(
  props: ICreateRoleProps,
  fragment: string = roleFragment
): Promise<ICreateRoleResponse> {
  const query = `
    mutation createRole($description: String!, $name: String!,$isInternal:Boolean!){
      createRole (description: $description, name: $name, isInternal:$isInternal) {
        ${fragment}
      }
    }
  `
  return (await clientWrapper(SERVICE_NAME.USERS, query, props)) as ICreateRoleResponse
}
