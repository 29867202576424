import { checkImageExists } from '@epic-front/common/src/helpers'
import {
  FileShield,
  MusicNoteCircle,
  MusicNotes,
  QuestionChat,
  Tag,
  User,
  VideoPlayerWebsite,
  XmarkShield,
  Youtube,
} from '@vectopus/atlas-icons-react'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import FeaturedIcon from './FeaturedIcon'

function getIconByType(
  type: 'release' | 'song' | 'artist' | 'asset' | 'video' | 'channel' | 'claim' | 'whitelistedChannel'
): JSX.Element {
  switch (type) {
    case 'release':
      return <MusicNotes weight="bold" />
    case 'song':
      return <MusicNoteCircle weight="bold" />
    case 'artist':
      return <User weight="bold" />
    case 'asset':
      return <FileShield weight="bold" />
    case 'video':
      return <Youtube weight="bold" />
    case 'channel':
      return <VideoPlayerWebsite weight="bold" />
    case 'claim':
      return <XmarkShield weight="bold" />
    case 'whitelistedChannel':
      return <Tag weight="bold" />
    default:
      return <QuestionChat weight="bold" />
  }
}

type TableContentTypeProps = {
  className?: string
  title: string | null
  subtitle?: string | null
  type: 'release' | 'song' | 'artist' | 'asset' | 'video' | 'channel' | 'claim' | 'whitelistedChannel'
  href?: string
  imgUrl?: string | null | undefined
  children?: React.ReactNode
  aTarget?: string
}

/**
 * TableContentType
 */
const TableContentType = ({
  className,
  title,
  subtitle,
  type,
  href,
  imgUrl,
  children,
  aTarget,
}: TableContentTypeProps): JSX.Element => {
  const icon = getIconByType(type) // Get icon based on type
  const Container = href ? Link : 'div' // Change container type if we add href
  const [hasExistingImage, setHasExistingImage] = useState(false)

  useEffect(() => {
    if (imgUrl) {
      checkImageExists(imgUrl).then(exists => {
        if (exists) {
          setHasExistingImage(true)
        }
      })
    }
  }, [imgUrl])

  return (
    <Container
      to={href || ''}
      target={aTarget}
      className={`table-content-type table-content-${type} d-flex gap-2 flex-row align-items-center ${className}`}
    >
      <div className="position-relative">
        {hasExistingImage && imgUrl && (
          <img
            className="position-absolute w-100 h-100 border rounded-3 overflow-hidden"
            src={imgUrl}
            alt={title || undefined}
            style={{ objectFit: 'cover', inset: 0, zIndex: 1 }}
          />
        )}
        <FeaturedIcon size="lg" icon={icon} className={` ${hasExistingImage ? 'bg-transparent text-white' : ''}`} />
      </div>
      <div className="d-flex flex-column gap-1 align-items-start lh-1 flex-grow-1">
        <div className="title font-16 fw-medium m-0">{title}</div>
        {type !== 'artist' && <div className="subtitle font-14 text-gray-500">{subtitle}</div>}
      </div>
      {children && <div className="d-flex flex-column gap-1 align-items-end lh-1 flex-grow-0">{children}</div>}
    </Container>
  )
}

export default TableContentType
