import { SERVICE_NAME } from '../../../constants'
import { IParticipantExternalLinkFormData } from '../../../models/assets/ParticipantBasic'
import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'
import { participantsFragment } from '../fragments'

export interface ILoadParticipantsResponseContent {
  uuid: string | null
  name: string | null
  role: string | null
  externalLinksToStores: IParticipantExternalLinkFormData[]
}

export type ILoadParticipantsResponse = IGenericResponse<
  'findAssetByUuid',
  { participants: ILoadParticipantsResponseContent[] }
>

export async function loadParticipants(
  uuid: string,
  fragment: string = participantsFragment
): Promise<ILoadParticipantsResponse> {
  const variables = { uuid }
  const query = `
    query loadParticipants ($uuid: String!) {
      findAssetByUuid (uuid:$uuid) {
        uuid
        participants {
          ${fragment}
        }
      }
    }
  `
  return clientWrapper(SERVICE_NAME.ASSETS, query, variables) as Promise<ILoadParticipantsResponse>
}
