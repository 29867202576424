import { IGenericResponse } from '../../api.types'
import { extendedUserFragment } from '../fragments'
import clientWrapper from '../../clientWrapper'
import { IUserBasic } from '../../../models/UserBasic.model'
import { SERVICE_NAME } from '../../../constants'

export type IGetUserResponse = IGenericResponse<'user', IUserBasic>

export type IGetUserProps = {
  uuid: string
}

export async function getUser(
  { uuid }: IGetUserProps,
  fragment: string = extendedUserFragment
): Promise<IGetUserResponse> {
  const query = `query user($uuid:String!){
    user(uuid:$uuid) {
      ${fragment}
    }
  }`

  return (await clientWrapper(SERVICE_NAME.USERS, query, { uuid })) as IGetUserResponse
}
