import { cast, flow, Instance, types } from 'mobx-state-tree'

import {
  IGetPitchVideosResponse,
  getPitchVideos,
  IGetPitchVideosProps,
} from '../../api/assets-api/songpitch/getPitchVideos'
import { Pagination } from '../Pagination.model'
import { PitchChannelVideo } from './PitchChannelVideo.model'

export const PitchChannelVideoList = types
  .model({
    list: types.array(PitchChannelVideo),
  })
  .volatile(() => ({
    pagination: Pagination.create({ totalItems: 1 }),
    loading: false,
    channelUuidFilter: '',
    pitchUuidFilter: '',
    pitcherUuidFilter: '',
    assetUuidFilter: '',
    statusFilter: '',
  }))

  .actions(self => ({
    setChannelUuidFilter: (channelUuid: string) => {
      self.channelUuidFilter = channelUuid
    },
    setPitchUuidFilter: (pitchUuidFilter: string) => {
      self.pitchUuidFilter = pitchUuidFilter
    },
    setPitcherUuidFilter: (pitcherUuidFilter: string) => {
      self.pitcherUuidFilter = pitcherUuidFilter
    },
    setStatusFilter: (status: string) => {
      self.statusFilter = status
    },

    setAssetUuidFilter: (uuid: string) => {
      self.assetUuidFilter = uuid
    },

    load: flow(function* () {
      try {
        self.loading = true
        const variables: IGetPitchVideosProps = {
          pagination: self.pagination.allQueryParams,
          filters: {
            ...(self.channelUuidFilter && { channelUuid: self.channelUuidFilter }),
            ...(self.pitcherUuidFilter && { pitcherUuid: self.pitcherUuidFilter }),
            ...(self.assetUuidFilter && { assetUuid: self.assetUuidFilter }),
            ...(self.statusFilter && { status: self.statusFilter }),
            ...(self.pitchUuidFilter && { pitchUuid: self.pitchUuidFilter }),
          },
        }
        const resp: IGetPitchVideosResponse = yield getPitchVideos(variables)

        if (resp?.data.data?.pitchVideos.pitchVideos) {
          self.list = cast(resp.data.data.pitchVideos.pitchVideos)
          self.pagination.setTotalItems(resp.data.data.pitchVideos.total)
        }
      } catch (e) {
        console.error(e)
      } finally {
        self.loading = false
      }
    }),
  }))

export type IPitchChannelVideoList = Instance<typeof PitchChannelVideoList>
