import { SERVICE_NAME } from '../../../../constants'
import { IClaimManager } from '../../../../models/ClaimManager.model'
import { IGenericResponse } from '../../../api.types'
import clientWrapper from '../../../clientWrapper'

export type IAssignClaimManagerResponse = IGenericResponse<'assignClaimManager', IClaimManager[]>

export type IAssignClaimManagerProps = {
  managerUuid: string
  userUuids: string[]
}

export async function assignClaimManager(input: IAssignClaimManagerProps): Promise<IAssignClaimManagerResponse> {
  const mutation = `
    mutation AssignClaimManager($input: ClaimManagerFilterInput!) {
      assignClaimManager(input: $input) {
        uuid
        userUuid
        managerUuid
        default
        createdAt
        updatedAt
        updatedBy
      }
    }
  `
  return (await clientWrapper(SERVICE_NAME.ASSETS, mutation, { input })) as IAssignClaimManagerResponse
}
