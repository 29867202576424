import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'

import { SERVICE_NAME } from '../../../constants'
import { IAnalytics } from '../../../models/analyticsDistribution/Analytics.model'

const defaultFragment = `
  assetUuid
  offlinePlays
  streams
  totalPlays
  releaseUuid
  asset 
  {
    uuid
    title
    releaseUuids
  }
`

export interface IGetTopStreamedSongsProps {
  filters: {
    startDate?: string
    endDate?: string
  }
  limit?: number
}

export type IGetTopStreamedSongsResponse = IGenericResponse<'topStreamedSongs', IAnalytics[]>

export async function getTopStreamedSongs(
  props: IGetTopStreamedSongsProps,
  fragment: string = defaultFragment
): Promise<IGetTopStreamedSongsResponse> {
  const query = `
    query topStreamedSongs($filters: AnalyticsFilters, $limit: Float) {
        topStreamedSongs(filters: $filters,limit: $limit) {
            ${fragment}
        }
    }
  `
  return clientWrapper(SERVICE_NAME.ASSETS, query, { props }) as Promise<IGetTopStreamedSongsResponse>
}
