import { PERMISSIONS } from 'e2e-utils'
import { IMenuItem } from './menu.types'
import { ADMIN_ROUTES } from '../../routes/platformRoutes/adminRoutes'

const MENU_ITEMS_ADMIN: IMenuItem[] = [
  { key: 'admin-dashboard', name: 'Admin Dashboard', isTitle: true },
  { ...ADMIN_ROUTES.ROOT, icon: 'uil-home-alt' },
  {
    key: 'users-holder',
    name: 'Users',
    icon: 'uil-users-alt',
    permissionsOr: [
      PERMISSIONS.PAGES.ADD_USERS,
      PERMISSIONS.PAGES.LIST_USERS,
      PERMISSIONS.PAGES.PERMISSIONS,
      PERMISSIONS.PAGES.MANAGE_SIGNUP_REQUESTS,
    ],
    children: [
      ADMIN_ROUTES.USER_ADD,
      ADMIN_ROUTES.USER_LIST,
      ADMIN_ROUTES.MANAGE_PERMISSIONS,
      ADMIN_ROUTES.ANNOUNCEMENTS_LIST,
      ADMIN_ROUTES.CONTACT_REQUESTS_LIST,
    ],
  },
  {
    key: 'earnings-payment',
    name: 'Earnings & Payments',
    icon: 'uil-money-bill',
    permissionsOr: [
      PERMISSIONS.PAGES.PAYMENTS,
      PERMISSIONS.PAGES.EARNINGS,
      PERMISSIONS.PAGES.SPONSORSHIPS,
      PERMISSIONS.PAGES.DISTRIBUTION_EARNINGS,
      PERMISSIONS.PAGES.MONTHLY_REPORT,
      PERMISSIONS.PAGES.MONTHLY_HIGHLIGHT_REPORT,
    ],
    children: [
      ADMIN_ROUTES.PAYMENT_LIST,
      ADMIN_ROUTES.EARNINGS_LIST,
      ADMIN_ROUTES.SPONSORSHIPS_LIST,
      ADMIN_ROUTES.DISTRIBUTION_EARNINGS,
      ADMIN_ROUTES.PAYMENT_ADMIN_REPORT,
      ADMIN_ROUTES.PAYMENT_ADMIN_HIGHLIGHT_REPORT,
    ],
  },
  {
    key: 'client-payment-details',
    name: 'Client Payment Detail',
    icon: 'uil-wallet',
    permissionsOr: [
      PERMISSIONS.PAGES.DOCUMENTS,
      PERMISSIONS.PAGES.MANAGE_USER_PAYMENT_METHODS,
      // add currency option here
    ],
    children: [ADMIN_ROUTES.MANAGE_TAX_FORMS, ADMIN_ROUTES.MANAGE_USER_PAYMENT_METHODS, ADMIN_ROUTES.CURRENCY_SETUP],
  },
  {
    key: 'onboarding',
    name: 'Onboarding',
    icon: 'uil-download-alt',
    permissionsOr: [
      PERMISSIONS.PAGES.DOCUMENTS,
      PERMISSIONS.PAGES.INVITATIONS,
      PERMISSIONS.PAGES.REVENUE_SPLIT_INVITATIONS,
    ],
    children: [
      ADMIN_ROUTES.ONBOARDING_OVERVIEW,
      ADMIN_ROUTES.MANAGE_INVITATIONS,
      ADMIN_ROUTES.MANAGE_CONTRACTS,
      ADMIN_ROUTES.MANAGE_REVENUE_SPLIT_INVITATIONS,
    ],
  },

  {
    key: 'channels-tab',
    name: 'Channels',
    icon: 'uil-film',
    permissionsOr: [PERMISSIONS.PAGES.CHANNELS, PERMISSIONS.PAGES.CHANNEL_VIDEOS],
    children: [ADMIN_ROUTES.CHANNEL_LIST, ADMIN_ROUTES.CHANNEL_VIDEOS],
  },
  {
    key: 'asset-management',
    name: 'Asset Management',
    icon: 'uil-books',
    permissionsOr: [
      PERMISSIONS.ASSET.MANAGE_ALL,
      PERMISSIONS.PAGES.CONTENT_ID_ASSETS,
      PERMISSIONS.PAGES.MANAGE_RELEASES,
      PERMISSIONS.PAGES.MANAGE_ANALYTICS,
      PERMISSIONS.PAGES.CLAIMS,
      PERMISSIONS.PAGES.MANAGE_IMPORTED_RELEASES,
      PERMISSIONS.PAGES.ISRC_MANAGER,
      PERMISSIONS.PAGES.AUDIO_RELEASE_LOGS,
      PERMISSIONS.PAGES.LICENSES,
    ],
    children: [
      ADMIN_ROUTES.ASSETS_ALL,
      ADMIN_ROUTES.ASSETS_CONTENT_ID,
      ADMIN_ROUTES.CLAIMS_CONTENT_ID,
      ADMIN_ROUTES.MANAGE_AUDIO_RELEASE,
      ADMIN_ROUTES.MANAGE_ANALYTICS,
      ADMIN_ROUTES.MANAGE_IMPORTED_AUDIO_RELEASE,
      ADMIN_ROUTES.MANAGE_ISRC_UPC,
      ADMIN_ROUTES.AUDIO_RELEASES_LOGS,
      ADMIN_ROUTES.LICENSES_LIST,
    ],
  },
  {
    key: 'content-id-issues',
    name: 'Content ID Issues',
    icon: 'uil-file-search-alt',
    permissionsOr: [
      PERMISSIONS.PAGES.CLAIMS,
      PERMISSIONS.PAGES.CLAIM_ISSUES_APPEALED,
      PERMISSIONS.PAGES.CLAIM_ISSUES_DISPUTED,
      PERMISSIONS.PAGES.CLAIM_ISSUES_POTENTIAL,
      PERMISSIONS.PAGES.CLAIM_ISSUES_RESOLVED,
      PERMISSIONS.PAGES.CLAIM_MANAGERS,
      PERMISSIONS.PAGES.CLAIM_EMAIL_TEMPLATES,
    ],
    children: [
      ADMIN_ROUTES.APPEALED_CLAIMS_CONTENT_ID,
      ADMIN_ROUTES.DISPUTED_CLAIMS_CONTENT_ID,
      ADMIN_ROUTES.POTENTIAL_CLAIMS_CONTENT_ID,
      ADMIN_ROUTES.RESOLVED_CLAIMS_CONTENT_ID,
      ADMIN_ROUTES.CLAIM_MANAGERS,
      ADMIN_ROUTES.CLAIM_EMAIL_TEMPLATES,
    ],
  },
  {
    key: 'channelWhitelisting',
    name: 'Channel Whitelisting',
    icon: 'uil-check',
    permissionsOr: [PERMISSIONS.PAGES.CHANNEL_WHITELISTING],
    children: [ADMIN_ROUTES.CHANNEL_WHITELISTING],
  },
  {
    key: 'content-upload',
    name: 'Content Upload',
    icon: 'uil-music',
    permissionsOr: [
      PERMISSIONS.PAGES.UPLOAD_AUDIO,
      PERMISSIONS.PAGES.UPLOAD_VIDEO,
      PERMISSIONS.PAGES.CREATE_AUDIO_RELEASE,
      PERMISSIONS.PAGES.DDEX,
      PERMISSIONS.PAGES.IMPORT_AUDIO_RELEASE,
    ],
    children: [
      ADMIN_ROUTES.CREATE_AUDIO_RELEASE,
      ADMIN_ROUTES.DDEX_UPLOAD,
      ADMIN_ROUTES.AUDIO_UPLOAD,
      ADMIN_ROUTES.AUDIO_UPLOAD_CSV,
      ADMIN_ROUTES.VIDEO_UPLOAD,
      ADMIN_ROUTES.IMPORT_ASSETS_SPOTIFY,
    ],
  },
  {
    key: 'client-assets-approvals',
    name: 'Client Assets Appr',
    icon: 'uil-headphones',
    permissionsOr: [PERMISSIONS.PAGES.MANAGE_AUDIOS, PERMISSIONS.PAGES.MANAGE_VIDEOS],
    children: [ADMIN_ROUTES.MANAGE_ADDED_AUDIOS, ADMIN_ROUTES.MANAGE_ADDED_VIDEOS],
  },
  {
    key: 'songpitch',
    name: 'SongPitch',
    icon: 'uil-record-audio',
    permissionsOr: [
      PERMISSIONS.PAGES.SONGPITCH_PITCHES,
      PERMISSIONS.PAGES.SONGPITCH_VIDEOS,
      PERMISSIONS.PAGES.ISRC_EMBEDDING,
    ],
    children: [ADMIN_ROUTES.PITCHES_LIST, ADMIN_ROUTES.PITCHES_VIDEO_LIST, ADMIN_ROUTES.ISRC_EMBED],
  },

  {
    key: 'configs',
    name: 'Configs',
    icon: 'uil-cog',
    permissionsOr: [
      PERMISSIONS.PAGES.MANAGE_USER_LABELS,
      PERMISSIONS.PAGES.MANAGE_USER_GROUPS,
      PERMISSIONS.PAGES.MANAGE_GENRES,
      PERMISSIONS.PAGES.REFRESH_AUDIOSALAD_TOKEN,
      PERMISSIONS.PAGES.REDIS_FLUSH_ALL,
      PERMISSIONS.PAGES.LOGS,
    ],
    children: [
      ADMIN_ROUTES.MANAGE_USER_LABELS,
      ADMIN_ROUTES.MANAGE_USER_GROUPS,
      ADMIN_ROUTES.MANAGE_GENRE_LIST,
      ADMIN_ROUTES.REFRESH_AUDIOSALAD_TOKEN,
      ADMIN_ROUTES.REDIS_FLUSH_ALL,
      ADMIN_ROUTES.LOG_LIST,
    ],
  },
]

export default MENU_ITEMS_ADMIN
