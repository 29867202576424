import { types, Instance } from 'mobx-state-tree'

import { AFFILIATE_TYPE } from '../../constants'

export const AffiliateBasic = types.model({
  uuid: types.string,
  type: types.maybeNull(types.enumeration(Object.values(AFFILIATE_TYPE))),
  entityUuid: types.maybeNull(types.string),
  affiliateUserUuid: types.maybeNull(types.string),
  percentage: types.maybeNull(types.number),
  siteUuid: types.maybeNull(types.string),
})

export type IAffiliateBasic = Instance<typeof AffiliateBasic>
