import { SERVICE_NAME } from '../../../constants'
import { IImageBasic } from '../../../models/assets/ImageBasic.model'
import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'
import { imageBasicFragment } from '../fragments'

interface IImageCreateUpdateProps {
  uuid?: string
  storageUrl?: string
}

export type IImageCreateUpdateResponse = IGenericResponse<'Image_createOrUpdate', IImageBasic>

export async function imageCreateOrUpdate(
  props: IImageCreateUpdateProps,
  fragment: string = imageBasicFragment
): Promise<IImageCreateUpdateResponse> {
  const variables = {
    patch: props,
  }

  const query = `
    mutation imageCreateOrUpdate ($patch: ImageInput!) {
       Image_createOrUpdate (patch: $patch) {
        ${fragment}
      }
    }
  `

  return clientWrapper(SERVICE_NAME.ASSETS, query, variables) as Promise<IImageCreateUpdateResponse>
}
