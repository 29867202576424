import { SERVICE_NAME } from '../../../constants'
import { IAssetBasic } from '../../../models/assets/AssetBasic.model'
import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'
import { assetFullFragmentLines } from '../fragments'
import { ICreateUpdateAssetProps } from './createOrUpdateAsset'

export type IBulkUpdateAssetResponse = IGenericResponse<'createOrUpdateAssets', IAssetBasic[]>

export async function bulkUpdateAssets(
  patches: ICreateUpdateAssetProps[],
  fragment: string = assetFullFragmentLines
): Promise<IBulkUpdateAssetResponse> {
  const variables = { patches }
  const query = `
    mutation bulkUpdateAssets($patches: [AssetInput!]!) {
      createOrUpdateAssets (
        patches: $patches
      ) {
        ${fragment} 
      }
    }
  `
  return clientWrapper(SERVICE_NAME.ASSETS, query, variables) as Promise<IBulkUpdateAssetResponse>
}
