import { Instance, types, destroy, flow } from 'mobx-state-tree'
import { imageDelete } from '../../api'
import { IImageDeleteResponse } from '../../api/types'
import { Image, IImageFormData } from './Image.model'

export const ImageList = types
  .model({
    list: types.array(Image),
  })
  .views(self => ({
    get uuids() {
      return self.list.map(img => img.uuid)
    },
    byUuid(uuid: string | null | undefined) {
      if (!uuid) {
        return undefined
      }
      return self.list.find(img => {
        return img.uuid === uuid
      })
    },
  }))
  .actions(self => ({
    add(info: IImageFormData) {
      self.list.push(info)
      const current = self.list[self.list.length - 1]
      current.createUpdate(info, 'Image created')
    },
    remove: flow(function* (imgUuid: string) {
      try {
        const resp: IImageDeleteResponse = yield imageDelete(imgUuid)
        if (resp && resp.data.data?.Image_delete) {
          // WE CREATE AN OBJECT TO PASS IT AND DESTROY IT HERE MAYBE WE SHOULD FIND IT AND DESTROY IT HERE
          const img = self.byUuid(imgUuid)
          destroy(img)
        }
      } catch (err) {
        console.error(err)
      }
    }),
  }))

export type IImageList = Instance<typeof ImageList>
