import { SERVICE_NAME } from '../../constants'
import { IImageBasic } from '../../models/assets/ImageBasic.model'
import { IGenericResponse } from '../api.types'
import clientWrapper from '../clientWrapper'

interface IGetImageUploadUrlProps {
  imageFileName: string
  imageUuid?: string
  releaseUuid: string
  imageType?: string
}

export type IGetImageUploadUrlResponse = IGenericResponse<
  'getImageUploadUrl',
  { uploadUrl: string; image: IImageBasic }
>

export async function getImageUploadUrl(props: IGetImageUploadUrlProps): Promise<IGetImageUploadUrlResponse> {
  const query = `
    query getImageUploadUrl ($imageFileName: String!, $imageUuid: String, $releaseUuid: String!,$imageType:ImageType) {
      getImageUploadUrl (
        imageFileName: $imageFileName,
        imageUuid: $imageUuid,
        releaseUuid:$releaseUuid
        imageType:$imageType
      )
      {
        uploadUrl
        image
        {
          uuid
          type
          fileName
          storagePath
          releaseUuid
          downloadUrl
        }
      }
    }
  `
  return clientWrapper(SERVICE_NAME.ASSETS, query, props) as Promise<IGetImageUploadUrlResponse>
}
