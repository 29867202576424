import { Instance, cast, flow, types } from 'mobx-state-tree'
import moment from 'moment'
import { IGetClaimDailyCountsResponse, getClaimDailyCounts } from '../../api/assets-api/getClaimDailyCounts'

export const ClaimDailyList = types
  .model({
    list: types.array(
      types.model({
        date: types.string,
        count: types.number,
      })
    ),
    percentageDifference: types.maybeNull(types.number),
    total: types.maybeNull(types.number),
  })
  .volatile(() => ({
    loading: true,
    assetUuidFilter: '',
  }))

  .actions(self => ({
    load: flow(function* () {
      try {
        self.list = cast([])
        self.loading = true
        const resp: IGetClaimDailyCountsResponse = yield getClaimDailyCounts(
          self.assetUuidFilter ? { assetUuid: self.assetUuidFilter } : undefined
        )
        const orderedDailyViews = resp?.data.data?.claimDailyCounts.days || []
        orderedDailyViews.sort((a, b) => (a.date > b.date ? 1 : -1))
        if (orderedDailyViews) {
          self.list = cast(orderedDailyViews)
          self.total = resp?.data.data?.claimDailyCounts.total || 0
          self.percentageDifference = resp?.data.data?.claimDailyCounts.percentage || null
        }
      } catch (e) {
        console.error(e)
      } finally {
        self.loading = false
      }
    }),
    setAssetUuidFilter: (uuid: string) => {
      self.assetUuidFilter = uuid
    },

    getCategs() {
      return self.list.map(el => moment(el.date).format('DD-MMM'))
    },
    getDataArray() {
      return self.list.map(el => el.count)
    },
  }))

export type IClaimDailyList = Instance<typeof ClaimDailyList>
