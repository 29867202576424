import { intersectionBy } from 'lodash'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { TOKEN_QUERY_NAME } from '@epic-front/common/src/constants'
import { IUser } from '@epic-front/common/src/models/User.model'

import { useQuery, useStore } from '../hooks'

type IProps = {
  roles?: string[]
  shouldHaveRoute?: (user?: IUser) => boolean
}

/**
 * Private Route forces the authorization before the route can be accessed
 */
const PrivateRoute = ({ roles, shouldHaveRoute }: IProps): JSX.Element => {
  const { auth } = useStore()

  const location = useLocation()
  const query = useQuery()
  const key = query.get(TOKEN_QUERY_NAME)

  useEffect(() => {
    const load = async () => {
      try {
        if (key) {
          auth.setToken(key)
          await auth.getMe()
        }
      } catch (e) {
        console.error(e)
      }
    }
    load()
  }, [auth, auth.isTokenExpired])

  // not logged in so redirect to login page with the return url
  if (!auth.isAuth) {
    return (
      <Navigate
        to={{ ...location, pathname: '/account/login' }}
        state={{ from: { pathname: window.location.pathname } }}
      />
    )
  }

  // check if token expired
  if (auth.isAuth && auth.isTokenExpired) {
    return (
      <Navigate
        to={{ ...location, pathname: '/account/session-expired' }}
        state={{ from: { pathname: window.location.pathname } }}
      />
    )
  }

  // check if route is restricted by role
  // role not authorized so redirect to home page
  if (roles && intersectionBy(roles, auth.roleNames).length === 0) {
    return <Navigate to={{ ...location, pathname: '/' }} />
  }

  // check if has extra condition and meets it
  if (auth.user && shouldHaveRoute && !shouldHaveRoute(auth.user)) {
    return <Navigate to={{ ...location, pathname: '/' }} />
  }

  // authorized so return child component
  return <Outlet />
}

export default observer(PrivateRoute)
