import { useEffect, useRef, useState } from 'react'
import ReactGA from 'react-ga4'
import { GA_EVENT_ACTIONS, GA_EVENT_CATEGORY } from '@epic-front/common'
import { VERSION } from '@epic-front/common/src/constants/general/general'
import { useStore } from '@hooks'
import Modal from '../blocks/basicModals/Modal'

const VersionModal = (): JSX.Element => {
  const { site } = useStore()
  const [show, setShow] = useState<boolean>(false)
  const intervalref = useRef<number | null>(null)

  const stopInterval = () => {
    if (intervalref.current) window.clearInterval(intervalref.current)
  }

  const handleClose = () => {
    setShow(false)
    stopInterval()
  }

  const handleOpen = () => {
    setShow(true)
    stopInterval()
    ReactGA.event({
      category: GA_EVENT_CATEGORY.DISPLAY_VERSION_MODAL,
      action: GA_EVENT_ACTIONS.SHOW,
      label: `Show Reload Modal: ${VERSION} - ${site?.version}`,
    })
  }

  const handleReload = () => {
    global.location.reload()
  }

  const parseVersion = (version: string) => {
    return version.split('.').map(v => Number(v))
  }

  const checkVersion = async () => {
    try {
      await site?.loadCurrent()

      const backendSiteVersion = site?.version
      const frontendSiteVersion = VERSION

      if (frontendSiteVersion === '-') return
      if (backendSiteVersion === undefined || backendSiteVersion === null) return

      const backendVersionMajor = parseVersion(backendSiteVersion)[0]
      const backendVersionMinor = parseVersion(backendSiteVersion)[1]
      const frontendVersionMajor = parseVersion(frontendSiteVersion)[0]
      const frontendVersionMinor = parseVersion(frontendSiteVersion)[1]

      if (
        backendVersionMajor > frontendVersionMajor ||
        (backendVersionMajor === frontendVersionMajor && backendVersionMinor > frontendVersionMinor)
      ) {
        handleOpen()
      }
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    intervalref.current = window.setInterval(checkVersion, 300 * 1000)

    return () => {
      stopInterval()
    }
  }, [])

  return (
    <Modal
      isOpen={show}
      title="New Version Available!"
      onHide={handleClose}
      buttons={
        <>
          <button type="button" className="btn btn-gray-400" onClick={handleClose}>
            <i className="uil-times me-2" />
            Close
          </button>
          <button type="button" className="btn btn-success" onClick={handleReload}>
            <i className="uil-refresh me-2" />
            Reload
          </button>
        </>
      }
    >
      <div className="alert alert-info text-center mb-0 d-flex flex-column gap-2 p-2">
        <i className="uil-refresh display-4" />
        <p className="mb-0">
          <strong>We have just rolled out an update to our Dashboard.</strong>
          <br />
          Please click the &quot;Reload&quot; button below to access the latest version of our website.
        </p>
      </div>
    </Modal>
  )
}

export default VersionModal
