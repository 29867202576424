import { SERVICE_NAME } from '../../../constants'
import { IUserBasic } from '../../../models/UserBasic.model'
import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'

export interface IToggleLabelProps {
  labelUuid: string
  userUuid: string
}

export type IToggleLabelResponse = IGenericResponse<'toggleLabel', IUserBasic>

export async function toggleLabel({ labelUuid, userUuid }: IToggleLabelProps): Promise<IToggleLabelResponse> {
  const query = `
    mutation ($labelUuid:String!, $userUuid: String!) {
      toggleLabel (
        labelUuid: $labelUuid
        userUuid: $userUuid
      ) {
        labels {
          uuid
          name
        }
      }
    }
  `

  return clientWrapper(SERVICE_NAME.USERS, query, { labelUuid, userUuid }) as Promise<IToggleLabelResponse>
}
