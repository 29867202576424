import { SERVICE_NAME } from '../../../constants'
import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'

export type IBulkDeleteAssetsResponse = IGenericResponse<'deleteAssets', boolean>

export async function bulkDeleteAssets(uuids: string[]): Promise<IBulkDeleteAssetsResponse> {
  const variables = {
    uuids,
  }
  // update here
  const query = `
    mutation bulkDeleteAssets ($uuids: [String!]!) {
      deleteAssets (uuids: $uuids) 
    }
  `
  return clientWrapper(SERVICE_NAME.ASSETS, query, variables) as Promise<IBulkDeleteAssetsResponse>
}
