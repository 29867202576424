import axios from 'axios'
import moment from 'moment'
import { toast } from 'react-toastify'
import { downloadCSV, getLocalToken } from '../../../helpers/utils'
import { CLAIM_TAKE_ACTION_ROLE } from '../../../constants'

interface IDownloadDisputedClaimsCSVProps {
  startDate?: string
  endDate?: string
  search?: string
  videoId?: string
  assetId?: string
  userUuids?: string[]
  siteUuid?: string
  cmsUuid?: string
  status?: string
  disputeReason?: string
  isPrivateVideo?: boolean
  historyEventType?: string
  isTakeActionExpired?: boolean
  takeActionRoles?: CLAIM_TAKE_ACTION_ROLE[]
  disputeNote?: string
}

const downloadClaimsCSV = async (filters: IDownloadDisputedClaimsCSVProps, title?: string): Promise<boolean> => {
  const download = async () => {
    try {
      const token = getLocalToken()
      const SITE_TOKEN = process.env.REACT_APP_SITE_TOKEN

      const res = await axios({
        url: `${process.env.REACT_APP_ASSETS_API_URL}/claimsIssuesList/csv`,
        method: 'get',
        headers: { sitetoken: SITE_TOKEN || '', authorization: token || undefined },
        params: { ...filters },
      })

      const start = moment(filters.startDate).startOf('month').format('YYYY-MM')
      const end = moment(filters.endDate).startOf('month').format('YYYY-MM')

      const filename = `${title ?? 'Claims'}: ${start !== end ? `${start} - ${end}` : `${start}`}`

      downloadCSV(res.data, filename)
    } catch (err) {
      toast.error('There was a problem while exporting Disputed Claims list.')
    }

    return true
  }

  const response = await download()

  return response
}

export default downloadClaimsCSV
