import { SERVICE_NAME } from '../../../constants'
import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'

export type IUpdateContactFormStatusResponse = IGenericResponse<'updateContactFormStatus', { uuid: string }>

export type IUpdateContactFormStatusProps = {
  uuid: string
  status: string
}

export async function updateContactFormStatus(
  props: IUpdateContactFormStatusProps
): Promise<IUpdateContactFormStatusResponse> {
  const query = `#graphql
   mutation updateContactFormStatus($status: ContactFormStatus!, $uuid: String!) {
       updateContactFormStatus(status: $status, uuid: $uuid)
       {
          uuid
       }
      
  }
  `

  return clientWrapper(SERVICE_NAME.USERS, query, props) as Promise<IUpdateContactFormStatusResponse>
}
