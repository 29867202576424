import { SERVICE_NAME } from '../../../constants'
import { IUserBasic } from '../../../models/UserBasic.model'
import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'

export type ISetParentClientProps = {
  userUuid: string
  parentUuid: string | null
}
export type ISetParentClientResponse = IGenericResponse<'setParentClient', IUserBasic>

export async function setParentClient({
  userUuid,
  parentUuid,
}: ISetParentClientProps): Promise<ISetParentClientResponse> {
  const query = `
    mutation setParentClient ($userUuid: String!, $parentUuid: String) {
      setParentClient (parentUuid: $parentUuid, userUuid: $userUuid) {
        parentClientUuid
      }
    }
  `
  return (await clientWrapper(SERVICE_NAME.USERS, query, { userUuid, parentUuid })) as ISetParentClientResponse
}
