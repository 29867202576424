import { SERVICE_NAME } from '../../../constants'
import { IUserBasic } from '../../../models/UserBasic.model'
import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'
import { userFragmentBasic } from '../fragments'

export type IGetUsersOptimisedResponse = IGenericResponse<'usersOptimised', IUserBasic[]>
export async function getUsersOptimised(fragment: string = userFragmentBasic): Promise<IGetUsersOptimisedResponse> {
  const query = `
    query getUsersOptimised {
      usersOptimised {
        ${fragment}
      }
    }
  `
  return (await clientWrapper(SERVICE_NAME.USERS, query)) as IGetUsersOptimisedResponse
}
