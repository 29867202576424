import { useEffect, useState } from 'react'
import { Alert } from 'react-bootstrap'
import { useDropzone } from 'react-dropzone'
import { ASSET_PREFIX, FileStruct, AUDIO_MIME_TYPES, VIDEO_MIME_TYPES } from '@epic-front/common/src/constants'
import { generateUuid } from '@epic-front/common/src/helpers'
import { ArrowUpCloud } from '@vectopus/atlas-icons-react'

interface IFileUploader {
  onFileUpload: (files: FileStruct[]) => void
  isAudio?: boolean
  isVideo?: boolean
  accept?: string
}

/** Tentative version of FileUploader (to have shared L&F component) decoupled from client-select, for SongPitch.
 * I'm talking with David about refactoring this to separate the reusable parts.
 * */
const FileUploaderArea = (props: IFileUploader): JSX.Element => {
  const [files, setFiles] = useState<FileStruct[]>([])
  const [submit, setSubmit] = useState<boolean>(false)

  const accept = (() => {
    if (props.accept) {
      return props.accept
    }
    if (props.isAudio) {
      return AUDIO_MIME_TYPES
    }
    if (props.isVideo) {
      return VIDEO_MIME_TYPES
    }
    return '*'
  })()

  useEffect(() => {
    if (files && submit) {
      props.onFileUpload(files)
      setSubmit(false)
    }
  }, [files, submit])

  const onDrop = (droppedFiles: File[]) => {
    const newFilesStruct = droppedFiles.map(file => ({
      file,
      uuid: generateUuid(ASSET_PREFIX),
      upload: true,
    }))
    setFiles(newFilesStruct)
    setSubmit(true) // HACK for SongPitch
  }

  const { getRootProps, getInputProps, fileRejections } = useDropzone({ onDrop, accept })

  return (
    <>
      <div className="dropzone" {...getRootProps()}>
        <div className="dz-message ">
          <input {...getInputProps()} />
          <ArrowUpCloud weight="bold" size={48} />
          <div className="fs-4 fw-semibold">Drop files here or click to upload</div>
        </div>
      </div>
      {!!fileRejections.length && (
        <Alert variant="danger">
          The following files have invalid file formats:{' '}
          {fileRejections.map(rejection => rejection.file.name).join(', ')}
        </Alert>
      )}
    </>
  )
}

export default FileUploaderArea
