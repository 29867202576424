import { SERVICE_NAME } from '../../../constants'
import { ILabel } from '../../../models/Label.model'
import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'

export type ILabelsResponse = IGenericResponse<'labels', ILabel[]>

export async function getLabels(): Promise<ILabelsResponse> {
  const query = `
    query getLabels {
      labels {
        uuid
        name
      }
    }
  `

  return clientWrapper(SERVICE_NAME.USERS, query) as Promise<ILabelsResponse>
}
