import { SERVICE_NAME } from '../../constants'
import { IAssetBasic } from '../../models/assets/AssetBasic.model'
import { IGenericResponse } from '../api.types'
import clientWrapper from '../clientWrapper'
import { assetFullFragmentLines } from './fragments'

export type IFindAssetByUuidResponse = IGenericResponse<'findAssetByUuid', IAssetBasic>

export async function findAssetByUuid(
  uuid: string,
  fragment: string = assetFullFragmentLines
): Promise<IFindAssetByUuidResponse> {
  const variables = { uuid }
  const query = `
    query findAssetByUuid ($uuid: String!) {
      findAssetByUuid(uuid:$uuid) {
        ${fragment}
      }
    }
  `
  return clientWrapper(SERVICE_NAME.ASSETS, query, variables) as Promise<IFindAssetByUuidResponse>
}
