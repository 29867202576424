import { flow, Instance, types } from 'mobx-state-tree'
import { toast } from 'react-toastify'
import { deleteGenre, IDeleteGenreResponse } from '../../api/assets-api/genres/deleteGenre'
import { GenreItem } from './GenreItem.model'

export const Genre = types
  .model({
    uuid: types.string,
    name: types.string,
    parentGenre: types.maybeNull(GenreItem),
    subGenres: types.array(GenreItem),
  })
  .volatile(() => ({
    loading: false,
  }))
  .actions(self => ({
    remove: flow(function* () {
      try {
        self.loading = true
        const resp: IDeleteGenreResponse = yield deleteGenre({ genreUuid: self.uuid })

        if (resp && resp.data.data?.deleteGenre) {
          toast.success('Genre deleted')
        }
        self.loading = false
      } catch (err) {
        self.loading = false
        console.error(err)
      }
    }),
  }))

export type IGenre = Instance<typeof Genre>
