import { Instance, cast, types, flow } from 'mobx-state-tree'

import { ANALYTICS_ASSET_TYPE, TOP_ASSETS_TYPE } from '../../constants'
import { getTopAssets, IGetTopStreamedSongsResponse } from '../../api/assets-api/assets/getTopAssets'
import { AssetBasic } from './AssetBasic.model'

export const TopAssetsList = types
  .model({
    list: types.array(
      types.model({
        asset: AssetBasic,
        claims: types.maybeNull(types.number),
        earnings: types.maybeNull(types.number),
        views: types.maybeNull(types.number),
      })
    ),
    sortBy: types.enumeration(Object.values(TOP_ASSETS_TYPE)),
    type: types.enumeration(Object.values(ANALYTICS_ASSET_TYPE)),
  })
  .volatile(() => ({
    loading: false,
    limit: 5,
    userUuidSelector: undefined as undefined | string,
  }))
  .actions(self => ({
    load: flow(function* () {
      try {
        self.loading = true

        const resp: IGetTopStreamedSongsResponse = yield getTopAssets({
          limit: self.limit,
          sortBy: self.sortBy,
          type: self.type,
          userUuid: self.userUuidSelector,
        })

        if (resp && resp.data.data?.topAssets) {
          self.list = cast(resp.data.data.topAssets)
        }
      } catch (e) {
        console.error(e)
      } finally {
        self.loading = false
      }
    }),
  }))

export type ITopAssetsList = Instance<typeof TopAssetsList>
