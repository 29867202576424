import { CLAIM_STATUSES } from '@epic-front/common'
import { channelMinimalFragment } from '@epic-front/common/src/api/assets-api/fragments'
import Auth from '@epic-front/common/src/models/Auth.model'
import { LabelList } from '@epic-front/common/src/models/LabelList.model'
import { RoleList } from '@epic-front/common/src/models/RoleList.model'
import { Site } from '@epic-front/common/src/models/Site.model'
import { SiteList } from '@epic-front/common/src/models/SiteList.model'
import { UserGroupList } from '@epic-front/common/src/models/UserGroupList.model'
import { UserList } from '@epic-front/common/src/models/UserList.model'
import { GenreList } from '@epic-front/common/src/models/assets'
import { ChannelList } from '@epic-front/common/src/models/assets/ChannelList.model'
import { CmsList } from '@epic-front/common/src/models/assets/CmsList.model'
import { ContentIdClaimList } from '@epic-front/common/src/models/assets/ContentIdClaimList.model'
import { PaymentMethodList } from '@epic-front/common/src/models/payment/PaymentMethodList.model'
import { PERMISSIONS } from 'e2e-utils'
import { Instance, types } from 'mobx-state-tree'
import { Layout } from './Layout.model'

const RootStore = types
  .model({
    layout: Layout,
    auth: Auth,
    labels: types.maybeNull(LabelList),
    userGroups: types.maybeNull(UserGroupList),
    site: types.maybeNull(Site),
    sites: types.maybeNull(SiteList),
    cmses: types.maybeNull(CmsList),
    roles: types.maybeNull(RoleList),
    users: types.maybeNull(UserList),
    paymentMethods: types.maybeNull(PaymentMethodList),
    channels: types.maybeNull(ChannelList),
    genres: types.maybeNull(GenreList),
    activeClaims: types.maybeNull(ContentIdClaimList),
  })
  .actions(self => ({
    init() {
      // we have auth here

      if (self.site) self.site.loadCurrent()

      if (self.labels && self.auth.hasPermission(PERMISSIONS.LABEL.LIST)) self.labels.load()
      if (self.userGroups && self.auth.hasPermission(PERMISSIONS.USER_GROUP.LIST)) self.userGroups.load()

      if (self.sites && self.auth.hasPermission(PERMISSIONS.SITE.LIST)) {
        self.sites.load()
      } else {
        self.sites?.setLoading(false)
      }

      if (self.cmses && self.auth.hasPermission(PERMISSIONS.SITE.LIST)) {
        self.cmses.load()
      } else {
        self.cmses?.setLoading(false)
      }

      if (self.roles && self.auth.hasPermission(PERMISSIONS.PERMISSION.LIST_ROLES)) {
        self.roles.load()
      } else {
        self.roles?.setLoading(false)
      }

      if (self.users) self.users.load()
      if (self.genres) self.genres.load()

      if (
        self.channels &&
        (self.auth.hasPermission(PERMISSIONS.CHANNEL.LIST_ALL) ||
          self.auth.hasPermission(PERMISSIONS.CHANNEL.LIST_SELF))
      ) {
        self.channels.load(true, channelMinimalFragment)
      } else {
        self.channels?.setLoading(false)
      }

      if (self.paymentMethods) self.paymentMethods.load()

      if (self.activeClaims && self.auth.hasPermission(PERMISSIONS.CLAIM.LIST_SELF)) {
        self.activeClaims.setStatusFilter(CLAIM_STATUSES.ACTIVE)
        self.activeClaims.load('')
      }
    },
  }))

export type IRootStore = Instance<typeof RootStore>

export default RootStore
