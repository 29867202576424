import clientWrapper from '../../clientWrapper'

import { IGenericResponse } from '../../api.types'

import { SERVICE_NAME } from '../../../constants'
import { IBulkClaimResponse } from './bulkUpdateClaimIssuesStatus'
import { claimRequestFragment } from './fragments'

export type IRequestLicensesByEmailProps = {
  email: string
  claimIds: string[]
  userUuid: string
  emailTemplateUuid?: string
}

export type IRequestLicensesByEmailResponse = IGenericResponse<'requestLicensesByEmail', IBulkClaimResponse[]>

export async function requestLicensesByEmail(
  { email, claimIds, userUuid, emailTemplateUuid }: IRequestLicensesByEmailProps,
  fragment: string = claimRequestFragment
): Promise<IRequestLicensesByEmailResponse> {
  const query = `
    mutation requestLicensesByEmail ($email: String!, $claimIds: [String!]!, $userUuid: String!, $emailTemplateUuid: String!) {
      requestLicensesByEmail (email: $email, claimIds: $claimIds, userUuid: $userUuid, emailTemplateUuid: $emailTemplateUuid) {
        ${fragment}
      }
    }
  `
  return clientWrapper(SERVICE_NAME.ASSETS, query, {
    email,
    claimIds,
    userUuid,
    emailTemplateUuid,
  }) as Promise<IRequestLicensesByEmailResponse>
}
