import { cast, flow, Instance, types } from 'mobx-state-tree'
import { getSites, IGetSitesResponse } from '../api/users-api/other/getSites'
import { ISite, Site } from './Site.model'

export const SiteList = types
  .model({
    list: types.array(Site),
  })
  .volatile(() => ({
    loading: true,
  }))
  .views(self => ({
    getSiteByUuid(uuid: string): ISite | undefined {
      return self.list.find(el => el.uuid === uuid)
    },
    getSiteBySlug(slug: string): ISite | undefined {
      return self.list.find(el => el.slug === slug)
    },
  }))
  .actions(self => ({
    setSites(sites: ISite[]) {
      self.list = cast(sites.map(el => Site.create(el)))
    },
  }))
  .actions(self => ({
    setLoading(val: boolean) {
      self.loading = val
    },
    load: flow(function* () {
      try {
        self.loading = true
        const resp: IGetSitesResponse = yield getSites()
        if (resp?.data?.data?.response) {
          self.setSites(resp.data?.data.response)
        }
        self.loading = false
      } catch (err) {
        self.loading = false
        console.error(err)
      }
    }),
  }))

export type ISiteList = Instance<typeof SiteList>
