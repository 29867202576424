import { SERVICE_NAME } from '../../../constants'
import { IAsset } from '../../../models/assets/Asset.model'
import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'
import { ddexUploadCsvFragment } from '../fragments'

export type IDdexUploadCsvProps = {
  csvString: string
  audios: string[]
  images: string[]
}

export type IDdexUploadCsvResponse = IGenericResponse<'ddexUploadCsv', { assets: IAsset[]; errors: string[] }>

export async function ddexUploadCsv(
  { csvString, audios, images }: IDdexUploadCsvProps,
  fragment: string = ddexUploadCsvFragment
): Promise<IDdexUploadCsvResponse> {
  const query = `
      mutation ddexUploadCsv($csvString: String!, $audios: [String!]!, $images: [String!]!) {
      ddexUploadCsv(csvString: $csvString, audios: $audios, images: $images) {
        assets {
          ${fragment}
        }
        errors 
      }
    }
  `
  return (await clientWrapper(SERVICE_NAME.ASSETS, query, { csvString, audios, images })) as IDdexUploadCsvResponse
}
