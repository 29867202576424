import { IAsset } from '@epic-front/common/src/models'

interface IPlayButton {
  onClick: () => void
  asset?: IAsset
  disabled?: boolean
}

export const PlayButton = ({ onClick, asset, disabled = false }: IPlayButton): JSX.Element => {
  return (
    <button
      type="button"
      className="btn btn-primary"
      tabIndex={0}
      aria-label="Play asset"
      onClick={onClick}
      disabled={disabled || asset?.storagePath === ''}
    >
      <i
        className="uil-play"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasBottom"
        aria-controls="offcanvasBottom"
      />
    </button>
  )
}
