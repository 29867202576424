export const claimFragment = `
    uuid
    users {
        userUuid
        revSplitUuid
        percentage
        isOwner
    }
    siteUuid
    claimId
    status
    viewCount
    assetId
    assetUuid
    assetTitle
    videoId
    videoTitle
    videoUploadDate
    claimDate
    expiresAt
    cmsUuid
    channelId
    channelName
    isPrivateVideo
    takeActionRole
    takeActionBy
    takeActionEmailSentAt
    takeActionExpiresAt
    takeActionRequestedStatus
    takeActionLicenseRequestedAt
    takeActionReason
    emailTemplateUuid
    autoReleasedAt
    autoReleasedType
`

export const claimHistoryFragment = `
    history {
        event {
            typeDetails {
                appealExplanation
                disputeNotes
                disputeReason
            }
        }
    }
`

export const claimsFragment = `
    claims {
        ${claimFragment}
    }
`

export const disputedClaimsFragment = `
    claims {
        ${claimFragment}
        ${claimHistoryFragment}
    }
`

export const claimRequestBasicFragment = `
    claimId
    isUpdated
    error
    claim {
        ${claimFragment}
    }
`
export const claimRequestFragment = `
    claimId
    isUpdated
    error
    claim {
        ${claimFragment}
        ${claimHistoryFragment}
    }
`
