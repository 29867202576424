import { ElementType } from 'react'
import { observer } from 'mobx-react-lite'
import { Button } from 'react-bootstrap'
import classNames from 'classnames'
import { IPagination } from '@epic-front/common/src/models/Pagination.model'

import { useDndTable } from '../../hooks'
import { ITableColumn } from './Table.types'

interface ITableRow<T> {
  row: T
  rowId: string
  rowIndex: number
  columns: ITableColumn<T>[]
  totalColumns: number

  expandedRows: number[]
  pagination: IPagination

  isShowingAdvanced?: boolean
  showRowNumber?: boolean
  rowNumberTop?: boolean
  isExpandable?: boolean
  isDraggable?: boolean
  isSelectable?: boolean
  selectedRows?: string[]

  rowClasses?: (row: T) => string
  selectableRowDisabled?: (row: T) => boolean
  handleOnDrop?: (rowUuid: string, newPosition: number) => void
  expandableRowDisabled?: (row: T) => boolean
  ExpandableRowComponent?: ElementType<{ row: T }>

  handleToggleRow: (rowId: string) => void
  handleToggleExpandRow: (rowIndex: number) => void
}

function TableRow<T>({
  row,
  rowId,
  rowIndex,
  columns,
  totalColumns,

  expandedRows,
  pagination,

  isShowingAdvanced,
  showRowNumber,
  rowNumberTop,
  isExpandable,
  isDraggable = false,
  isSelectable,
  selectedRows,

  rowClasses,
  selectableRowDisabled,
  handleOnDrop,
  expandableRowDisabled,
  ExpandableRowComponent,

  handleToggleRow,
  handleToggleExpandRow,
}: ITableRow<T>): JSX.Element {
  const { draggable, dragover, dragging, rowRef, dragHandlerRef } = useDndTable({
    isDraggable,
    rowIndex,
    rowId,
    onDrop: (rowUuid: string, newPosition: number) => {
      if (handleOnDrop) handleOnDrop(rowUuid, newPosition)
    },
  })

  const itemNumber = pagination.perPage * (pagination.page - 1) + (rowIndex + 1)

  return (
    <>
      <tr
        className={`${rowClasses ? rowClasses(row) : ''} ${classNames({
          'opacity-25': dragging,
          'opacity-25 transition-25': dragover,
        })}`}
        draggable={draggable}
        ref={rowRef}
      >
        {isSelectable && typeof selectedRows !== 'undefined' && (
          <td className="mincol">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                checked={selectedRows.includes(rowId)}
                onChange={() => {
                  handleToggleRow(rowId)
                }}
                disabled={selectableRowDisabled && selectableRowDisabled(row)}
              />
            </div>
          </td>
        )}
        {/* Adding an extra column if the row is expandable with an expand button */}
        {isExpandable && (
          <td className="mincol">
            <button
              className="btn btn-sm btn-light"
              type="button"
              disabled={expandableRowDisabled && expandableRowDisabled(row)}
              onClick={() => {
                handleToggleExpandRow(rowIndex)
              }}
            >
              <i
                style={{ fontSize: '1.25em' }}
                className={classNames('', expandedRows.indexOf(rowIndex) > -1 ? 'uil uil-times' : 'uil uil-plus')}
              />
            </button>
          </td>
        )}
        {/* Show the row number based on all pages  */}
        {showRowNumber && (
          <td>
            <div className="d-flex justify-content-center align-items-center gap-1">
              <span
                className={classNames('badge badge-performance', {
                  'perf-first': itemNumber === 1 && rowNumberTop,
                  'perf-second': itemNumber === 2 && rowNumberTop,
                  'perf-third': itemNumber === 3 && rowNumberTop,
                })}
              >
                {itemNumber}
              </span>
              {isDraggable && (
                <Button
                  variant="outline-gray-400 bg-white text-gray-600"
                  className="py-0 px-1 grabbable ms-1 d-none d-md-block"
                  ref={dragHandlerRef}
                >
                  <i className="uil-arrow-break fs-5 grabbable" />
                </Button>
              )}
            </div>
          </td>
        )}

        {/* Listing the columns content */}
        {columns.map(col =>
          col.hidden && !isShowingAdvanced ? null : (
            <td className={classNames(col.cellClasses)} key={col.accessor || col.Header.toString()}>
              {
                // this is ok as we don't know the row interface here
                /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
                col.Cell ? col.Cell(row) : col.accessor && (row as any)[col.accessor]
              }
            </td>
          )
        )}
      </tr>

      {/* Adding the expanded content as a new row */}
      {isExpandable && expandedRows.indexOf(rowIndex) > -1 && (
        <tr>
          <td className="innertable-wrap shadow-inner p-3" colSpan={totalColumns}>
            <>{ExpandableRowComponent && <ExpandableRowComponent row={row} />}</>
          </td>
        </tr>
      )}
    </>
  )
}

export default observer(TableRow)
