import { SERVICE_NAME } from '../../../constants'
import { ISiteBasic } from '../../../models/SiteBasic.model'
import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'
import { siteExtendedFragment } from '../fragments'

export type IGetSiteResponse = IGenericResponse<'site', ISiteBasic[]>

export async function getSite(fragment: string = siteExtendedFragment): Promise<IGetSiteResponse> {
  const query = `
    query site {
       site {
        ${fragment}
      }
    }
  `
  return (await clientWrapper(SERVICE_NAME.USERS, query)) as IGetSiteResponse
}
