import { Button } from 'react-bootstrap'
import { Modal } from '@blocks'

interface IApproveModalProps {
  open: boolean
  closeModal: () => void
  submit: () => void
}

export const ApproveModal = ({ open, closeModal, submit }: IApproveModalProps): JSX.Element => {
  const Buttons = (
    <>
      <Button className="btn btn-gray-400" onClick={closeModal}>
        <i className="uil-times me-2" />
        Cancel
      </Button>
      <Button variant="success" onClick={submit}>
        <i className="uil-check me-2" />
        Confirm Approve
      </Button>
    </>
  )

  return (
    <Modal buttons={Buttons} onHide={closeModal} title="Confirm Approve" isOpen={open} size="md">
      <h5 className="alert alert-warning mb-0 text-center">Are you sure you want to confirm this?</h5>
    </Modal>
  )
}
