export const ASSET_UUID_REGEX = /^ast-[0-9a-z]{10}$/

export const ASSET_ID_REGEX = /^A[0-9]{15}$/

export const ASSET_IDS_SPLIT_REGEX = /[^a-zA-Z0-9-]/

export const YOUTUBE_VIDEO_URL_OR_ID_REGEX =
  /^\s*(?:https?:\/\/)?(?:(?:www\.)?youtube.com\/watch\?v=|youtu.be\/)?([^?/&;\s]+)[/?&;]?.*\s*$/

export const YOUTUBE_VIDEO_ID_EXTRACTOR_REGEX =
  /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/

export const YOUTUBE_CHANNEL_NAME_EXTRACTOR_REGEX = /^\s*(?:https?:\/\/)?(?:(?:www\.)?youtube.com\/@)[\w-]+\s*$/

export const CHANNEL_UUID_REGEX = /^chn-[0-9a-z]{10}$/

export const CHANNEL_ID_REGEX = /^UC[\w-]{21}[AQgw]$/

export const LOG_MESSAGE_REGEX = /^-?\d+\.\d+$/

export const VALID_ASSET_TITLE_REGEX = /^(?! *$).*$/

export const VALID_ISRC_REGEX = /^\s*([a-zA-Z]{2}[a-zA-Z0-9]{3}\d{7})\s*$/

export const VALID_ISWC_REGEX = /(^T\d{10}$|^$)/

export const VALID_UPC_REGEX = /(^\d{12,13}$|^$)/

export const PHONE_REGEX = /^((\+?[1-9]{1,4}[ -]?)|(([0-9]{2,3})[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}?[ -]?[0-9]{3,4}?$/

export const PHONE_REGEX_OPTIONAL =
  /^$|^((\+?[1-9]{1,4}[ -]?)|(([0-9]{2,3})[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}?[ -]?[0-9]{3,4}?$/

export const VALID_EMAIL_REGEX = /^[a-zA-Z0-9_!#$%&'*+/=?`{|}~^.-]+@[a-zA-Z0-9.-]+$/

export const FILENAME_WITH_EXTENSION_REGEX = /[^\\]*\.(\w+)$/

export const NUMBER_WITH_MAX_2_DIGITS_AFTER_COMMA = /^\d+(\.\d{0,2})?$/
