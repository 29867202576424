import { APPROVAL_STATES, MAX_RETRIES, SERVICE_NAME } from '../../../constants'
import { IAssetBasic } from '../../../models/assets/AssetBasic.model'
import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'

interface IChangeAssetStateProps {
  itemId: string
  targetState: APPROVAL_STATES
  comment?: string
}

export type IChangeAssetStateResponse = IGenericResponse<'changeAssetState', IAssetBasic>

export async function changeAssetState(props: IChangeAssetStateProps): Promise<IChangeAssetStateResponse> {
  const query = `
    mutation changeAssetState (
     $changeStateData: ChangeStateInput!
    ){
      changeAssetState (
        changeStateData: $changeStateData
      ){
        uuid
        assetState
      }
    }
  `
  return clientWrapper(
    SERVICE_NAME.ASSETS,
    query,
    { changeStateData: props },
    MAX_RETRIES
  ) as Promise<IChangeAssetStateResponse>
}
