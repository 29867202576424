import { SERVICE_NAME } from '../../../constants'
import { IUserBasic } from '../../../models/UserBasic.model'
import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'
import { extendedUserFragment } from '../fragments'

export type IGetMeResponse = IGenericResponse<'me', IUserBasic>

export async function getMe(): Promise<IGetMeResponse> {
  const query = `
  query me {
    me {
      ${extendedUserFragment}
    }
  }
  `

  return (await clientWrapper(SERVICE_NAME.USERS, query)) as IGetMeResponse
}
