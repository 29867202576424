import { Instance, types } from 'mobx-state-tree'
import { SiteBasic } from './SiteBasic.model'

export const UserSite = types.model({
  uuid: types.string,
  siteUuid: types.string,
  isMain: types.boolean,
  site: types.maybeNull(SiteBasic),
})

export type IUserSite = Instance<typeof UserSite>
